import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Grid, Button, TextField } from '@material-ui/core';
import DropzoneFiles from './dropzone-files';
import { deleteFromStorageProvider } from '../../core/http/functionRequests';
import { tokenExpired } from '../../core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../core/actions';
import { MultiSelectNative } from '../control/calendar/forms/fields/multi-select-native';

const DialogSaveDocuments = (props) => {
  const {
    openDialog,
    submitActions,
    tagsSelect,
    onCancel,
    currentDocuments,
  } = props;

  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const [documents, setDocuments] = useState({})
  const [tags, setTags] = useState([])
  const [description, setDescription] = useState("")
  const [name, setName] = useState("")
  const [errorName, setErrorName] = useState("")
  const [loading, setLoading] = useState(false)

  const handleCancel = async () => {
    const documentsBlobId = Object.values(documents);
    setLoading(true)
    try {
      if (documentsBlobId.length > 0) {
        const promise = documentsBlobId.map(document => {
          if (!!document) {
            return deleteFromStorageProvider(`${document.storedName}${document.extension}`)
          }
          return null
        })

        await Promise.allSettled(promise)
      }
    } catch (error) {
      console.error(error);
    } finally {
      onCancel()
    }
  }

  const handleChangeName = (e) => {
    const documentName = e.target.value
    setName(documentName)
    if (currentDocuments.find(el => el.name === documentName.trim())) {
      setErrorName("Ya existe el nombre en la ubicación actual.")
    } else {
      setErrorName("")
    }
  }

  const handleChangeDesc = (e) => {
    setDescription(e.target.value)
  }

  const handleChangeTags = (value) => {
    setTags(value ? value.map(el => el.label) : [])
  }

  const handleSave = () => {
    const data = {
      documents: Object.values(documents).filter(el => el),
      description,
      tags
    }
    if (data.documents.length === 1) {
      data.documents[0].name = name;
    }

    submitActions(data)
  }

  useEffect(() => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
  }, [])

  return (
    <Dialog open={openDialog}>
      <DialogTitle>
        Crear Documento
      </DialogTitle>

      <DialogContent style={{ minHeight: "350px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h3 style={{ fontSize: "1rem", marginTop: "1rem" }}>
              Adjuntar Archivo*
            </h3>
            <DropzoneFiles
              setDocuments={setDocuments}
              documents={Object.values(documents)}
              currentDocuments={currentDocuments}
              docName={name}
              setName={setName}
            />
          </Grid>

          {
            Object.values(documents)
              .filter(el => el).length < 2 && (
              <Grid item xs={12}>
                <TextField
                  id={"name"}
                  value={name}
                  onChange={handleChangeName}
                  label={"Nombre"}
                  variant='outlined'
                  required
                  fullWidth
                  size='small'
                  error={errorName.length > 0}
                  helperText={errorName}
                />
              </Grid>
            )
          }

          <Grid item xs={12}>
            <TextField
              id={"descripcion"}
              value={description}
              onChange={handleChangeDesc}
              label={"Descripción (opcional)"}
              variant='outlined'
              fullWidth
              size='small'
            />
          </Grid>

          <Grid item xs={12}>
            <MultiSelectNative
              label="Seleccionar etiquetas (opcional)"
              name='tags'
              options={tagsSelect}
              value={tags.map(el => ({ search: el, label: el }))}
              onChange={handleChangeTags}
            />
          </Grid>
        </Grid>
      </DialogContent>


      <DialogActions>
        <Grid
          style={{ marginBottom: "0.5rem" }}
          className="mt-3"
          container
          justifyContent="flex-end"
        >

          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            disabled={
              (Object.values(documents).filter(el => el?.loading)).length > 0 ||
              loading
            }
          >
            Cancelar
          </Button>

          <Button
            className="ml-2"
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              Object.values(documents).filter(el => el).length === 0 ||
              Object.keys(documents).filter(el => documents[el]?.loading).length > 0 ||
              errorName.length > 0 ||
              loading
            }
          >
            Guardar
          </Button>

        </Grid>
      </DialogActions>
    </Dialog >
  );
}

export default DialogSaveDocuments