import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { AlertComponent } from '../../AlertComponent';
import { DialogComponent } from '../../control/calendar/forms/new-activity/signature/DialogComponent';
import { TableData } from '../../Tables/Business-management/TableData';
import { cancelSetFlow, editNotifications, editReleasers, getSetDetails, releaseGroup } from '../../../core/http/functionRequests';
import SignersStatus from './SignersStatus';
import { PopUpReleasers } from './PopUpReleasers';
import { SignatureNotificationConfig } from '../../control/calendar/forms/new-activity/signature/SignatureNotificationConfig';
import { AlertConfirm } from '../../from-library/alerts/AlertConfirm';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../core/actions';
import { tokenExpired } from '../../../core/utils';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  dialogActivity: {
    padding: "8px",
  },
  actions: {
    padding: "24px 24px",
  },
  actionsCanceled: {
    padding: "0px 24px 24px 24px",
  },
  cancelado: {
    color: "red",
    fontWeight: "bold"
  },
  firmadoDesc: {
    color: '#2a4298',
    fontWeight: 'bold !important'
  },
  margin: {
    marginBottom: "8px",
    marginLeft: "10px",
    width: "15px"
  },
}));

const expirationDates = {
  '12h': "Cada 12 horas",
  '1d': "Cada 1 día",
  '2d': "Cada 2 días",
  '3d': "Cada 3 días",
  '4d': "Cada 4 días",
  '5d': "Cada 5 días",
  '6d': "Cada 6 días",
  '7d': "Cada 7 días",
};

const SignatureSetStatus = (props) => {
  const { setId, name, onClose, open, currentUser } = props;

  const classes = useStyles();
  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);
  const dispatch = useDispatch();

  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [dataSet, setDataSet] = useState(null)
  const [openSigners, setOpenSigners] = useState(false)
  const [openReleasers, setOpenReleasers] = useState(false)
  const [openLoader, setOpenLoader] = useState(false)
  const [openEditNotifications, setOpenEditNotifications] = useState(false)
  const [alert, setAlert] = useState({ open: false, message: '', type: 'error' })
  const [alertConfirm, setAlertConfirm] = useState({ open: false, title: '', textContent: '', type: '' })

  const closeAlert = () => setAlert({ open: false, message: '', type: 'error' })
  const openAlert = (message, type) => setAlert({ open: true, message, type })

  const closeAlertConfirm = () => setAlertConfirm({ open: false, title: '', textContent: '', type: '' })
  const openAlertConfirm = (title, textContent, type) => setAlertConfirm({ open: true, title, textContent, type })

  const requestSetInformation = async () => {
    try {
      if (isLoggedIn && tokenExpired()) {
        dispatch(authActions.userLoggedOut())
        return
      }
      setOpenLoader(true)
      const request = await getSetDetails(setId)
      setDataSet(request)
    } catch (error) {
      openAlert("Error leyendo datos", "error")
    } finally {
      setOpenLoader(false)
    }
  }

  const handleSubmitReleasers = async (releasers) => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    try {
      setOpenReleasers(false)
      setOpenLoader(true)

      await editReleasers(setId, { releasers: releasers.map(el => el.id) })
      const request = await getSetDetails(setId)

      setDataSet(request)
      openAlert("Editado correctamente", "success")
    } catch (error) {
      console.error(error);
      openAlert("Error editando quien libera", "error")
    } finally {
      setOpenLoader(false)
    }
  }

  const handleSaveNotifications = async (id, data) => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    try {
      setOpenEditNotifications(false)
      setOpenLoader(true)

      await editNotifications(id, data)
      const request = await getSetDetails(setId)

      setDataSet(request)
      openAlert("Editado correctamente", "success")
    } catch (error) {
      console.error(error);
      openAlert("Error editando las notificaciones", "error")
    } finally {
      setOpenLoader(false)
    }
  }

  const handleRelease = async () => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    try {
      setOpenLoader(true)
      await releaseGroup(setId)
      const request = await getSetDetails(setId)

      setDataSet(request)
      openAlert("Liberado correctamente", "success")
    } catch (error) {
      console.error(error);
      openAlert("Error liberando grupo", "error")
    } finally {
      setOpenLoader(false)
    }
  }

  const handleConfirmAlert = () => {
    if (alertConfirm.type === "release") {
      handleRelease()
      closeAlertConfirm()
    }
  }

  const handleOpenConfirm = (type) => {
    const title = "Liberar Grupo"
    const textContent = "Al liberar, notificará a los firmantes incluyendo los documentos firmados."
    openAlertConfirm(title, textContent, type)
  }

  const handleCancelSetFlow = async (reason) => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    try {
      setOpenLoader(true);
      setOpenConfirmAlert(false);
      console.log("cancelar flujo del grupo");
      await cancelSetFlow(setId, { reason })
      setOpenLoader(false);
      openAlert("Flujo cancelado correctamente", 'success')
    } catch (error) {
      console.error(error);
      setOpenLoader(false);
      openAlert("Se ha producido un error cancelando el flujo", 'error')
    } finally {
      requestSetInformation()
    }
  }

  useEffect(() => {
    requestSetInformation()
  }, [])

  return (
    <>
      <Dialog className={classes.dialogActivity} open={open} maxWidth={"md"}>
        <DialogTitle>
          <Grid container spacing={3} justifyContent='space-between'>
            <Grid item>
              <span>Estatus - {name}</span>
            </Grid>
            <Grid item>
              {(
                dataSet &&
                dataSet.pending &&
                !dataSet.signed &&
                (dataSet.releaseBy.length > 0 ? dataSet.releaseBy.length !== dataSet.releasedBy.length : true)
              ) ? (
                <Button
                  onClick={() => setOpenConfirmAlert(true)}
                  color="primary"
                  variant="contained">
                  Cancelar Flujo
                </Button>
              ) : <></>
              }
              {
                dataSet && dataSet.pending && dataSet.signed ? (
                  <>
                    {
                      !!dataSet.releaseBy.find(el => el.id === currentUser.userId) &&
                        !dataSet.releasedBy.includes(currentUser.userId)
                        ? (
                          <Button
                            onClick={() => handleOpenConfirm("release")}
                            color="primary"
                            variant="contained">
                            Liberar
                          </Button>
                        )
                        : <></>
                    }
                  </>
                ) : <></>
              }
              {
                dataSet?.canceled && (<span className={classes.cancelado}>CANCELADO</span>)
              }
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid
              container
              item
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs>
                {
                  dataSet ? (
                    <TableData
                      values={dataSet.data}
                      containerStyles={{ maxHeight: "300px" }}
                    />
                  ) : <></>
                }
              </Grid>
            </Grid>

            <Grid item container spacing={3}>

            </Grid>

            <Grid container item>
              <Grid item xs>
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>

                        <TableCell>
                          <strong>Invitados</strong>
                        </TableCell>

                        <TableCell>
                          <strong>Libera</strong>
                        </TableCell>

                        <TableCell>
                          <strong>Creado</strong>
                        </TableCell>

                        <TableCell>
                          <strong>Recordatorio, Expira</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {dataSet && dataSet.signers.map((user, index) => (
                            <div
                              className={`${index >= 3 ? "" : "col-xs-1"}`}
                              key={uuidv4()}
                              onClick={() => setOpenSigners(true)}>
                              {index >= 3 ? undefined : index == 2 ? (
                                <div
                                  style={{
                                    marginLeft: `-${8 * index}px`,
                                    background: user.color,
                                  }}
                                  className="text-center circle-user">
                                  {dataSet?.signers.length + 1 - 3}+
                                </div>
                              ) : (
                                <div
                                  style={{
                                    marginLeft: `-${8 * index}px`,
                                    background: user.color,
                                  }}
                                  className="text-center circle-user"
                                  title={user.name}>
                                  {user.name.toString().substring(0, 1)}
                                </div>
                              )}
                            </div>
                          ))}
                        </TableCell>

                        <TableCell>
                          {dataSet && dataSet.releaseBy.map((user, index) => (
                            <div
                              className={`${index >= 3 ? "" : "col-xs-1"}`}
                              key={uuidv4()}
                              onClick={() => setOpenReleasers(true)}>
                              {index >= 3 ? undefined : index == 2 ? (
                                <div
                                  style={{
                                    marginLeft: `-${8 * index}px`,
                                    background: user.color,
                                  }}
                                  className="text-center circle-user">
                                  {dataSet?.releaseBy.length + 1 - 3}+
                                </div>
                              ) : (
                                <div
                                  style={{
                                    marginLeft: `-${8 * index}px`,
                                    background: user.color,
                                  }}
                                  className="text-center circle-user"
                                  title={user.completeName}>
                                  {user.completeName.toString().substring(0, 1)}
                                </div>
                              )}
                            </div>
                          ))}
                        </TableCell>

                        <TableCell>
                          {dataSet
                            ? new Date(
                              dataSet.date
                            ).toLocaleDateString("es-Mexico", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })
                            : "-"}
                        </TableCell>

                        <TableCell>
                          {
                            dataSet
                              ? `${expirationDates[dataSet.remind_every]}, el `
                              : "-"
                          }

                          {
                            dataSet &&
                              moment.duration(
                                new moment().diff(dataSet.expiration_date)
                              ) > 0 ? (
                              <span
                                style={{ color: "red", fontWeight: "bold" }}>
                                {
                                  new Date(
                                    dataSet.expiration_date + "GMT-6"
                                  ).toLocaleDateString("es-Mexico", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                  )
                                }
                              </span>
                            ) : dataSet ? (
                              new Date(
                                dataSet.expiration_date + "GMT-6"
                              ).toLocaleDateString("es-Mexico", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })
                            ) : (
                              ""
                            )
                          }

                          {(
                            !dataSet?.canceled &&
                            !dataSet?.signed &&
                            (dataSet?.releaseBy.length > 0 ? dataSet.releaseBy.length !== dataSet.releasedBy.length : true)
                          ) && (
                              <IconButton
                                className={classes.margin}
                                size="small"
                                color="primary"
                                onClick={() => setOpenEditNotifications(true)}>
                                <EditIcon />
                              </IconButton>
                            )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            {dataSet?.canceled && (
              <Grid item container alignItems="center">
                <Grid item xs={3}>
                  <p style={{ color: "#5C7BF0" }}>Motivo de cancelación</p>
                </Grid>
                <Grid item xs={9}>
                  <p>{dataSet.reason}</p>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className={dataSet?.canceled ? classes.actionsCanceled : classes.actions}>
          <Grid container item justifyContent="flex-end" xs={12}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={onClose}>
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        <Backdrop open={openLoader} className={classes.backdrop}>
          <CircularProgress />
        </Backdrop>
      </Dialog>

      {openConfirmAlert && (
        <DialogComponent
          open={openConfirmAlert}
          title={"Cancelar flujo de grupo de firma"}
          setOpen={(value) => setOpenConfirmAlert(value)}
          textContent={
            <p>
              Al cancelar este grupo de firma, ya no se permitirá concluir con
              las firmas faltantes. <br />
              Se enviará una notificación a todos los firmantes que recibieron
              notificación de firma. <br />
              Una vez cancelado el flujo, ya no se puede reactivar.
            </p>
          }
          onConfirm={handleCancelSetFlow}
          onCancel={() => setOpenConfirmAlert(false)}
        />
      )}

      {
        openSigners && (
          <Dialog
            className="notification-dialog"
            open={openSigners}
          >
            <SignersStatus
              hasOrder={dataSet.ordered}
              users={dataSet.signers}
              canceled={dataSet.canceled}
              hasNom151={dataSet.hasNom151}
              signatureSet={{ username: dataSet.creator, id: dataSet.id }}
              expired={dataSet.expiration_date && moment.duration(new moment().diff(dataSet.expiration_date)) > 0}
              onClose={() => setOpenSigners(false)}
            />
          </Dialog>
        )
      }

      {
        openReleasers && (
          <PopUpReleasers
            open={openReleasers}
            vaultId={dataSet.vaultId}
            selected={dataSet.releaseBy.map(el => ({ ...el, name: el.completeName }))}
            releasedBy={dataSet.releasedBy}
            onCancel={() => setOpenReleasers(false)}
            onSubmit={handleSubmitReleasers}
            pending={dataSet.pending}
            canceled={dataSet.canceled}
          />
        )
      }

      {
        openEditNotifications && (
          <SignatureNotificationConfig
            edit
            open={openEditNotifications}
            onCancel={() => setOpenEditNotifications(false)}
            onSave={handleSaveNotifications}
            prev={{ ...dataSet }}
          />
        )
      }

      {alert.open && (
        <AlertComponent
          open={alert.open}
          title={alert.message}
          type={alert.type}
          onConfirm={closeAlert}
        />
      )}

      {
        alertConfirm.open && (
          <AlertConfirm
            open={alertConfirm.open}
            title={alertConfirm.title}
            textContent={alertConfirm.textContent}
            onConfirm={handleConfirmAlert}
            onCancel={closeAlertConfirm}

          />
        )
      }

    </>
  )
}

export default SignatureSetStatus