const { axiosRequestBlockChain, axiosCalendarRequest, authHeader } = require("../../configs/axios.config");

export class DocumentsHttp {
  getDocumentsByPath(path, success, error) {
    axiosRequestBlockChain.get(`/document?path=${path}`, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  createDocument(path, data, success, error) {
    axiosRequestBlockChain.post(`/document`, data, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  saveDocuments(data, success, error) {
    axiosRequestBlockChain.post(`/documents`, data, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  editDocument(data, success, error) {
    axiosRequestBlockChain.put(`/document`, data, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  editDocumentInformation(data, success, error) {
    axiosRequestBlockChain.put(`/document/information`, data, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  deleteDocument(data, success, error) {
    axiosRequestBlockChain.delete(`/document`, { headers: authHeader(), data: data })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  getDocumentInfo(documentId, success, error) {
    axiosRequestBlockChain.get(`/document/details/?documentId=${documentId}`, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  getContractsByCompany(companyId, path, success, error) {
    axiosRequestBlockChain.get(`/document?companyId=${companyId}&path=${path}`, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  downloadDocument(documento, success, error) {
    axiosRequestBlockChain.post(`/document/download`, documento, {
      headers: authHeader(),
      responseType: 'arraybuffer'
    })
      .then((data) => success(data))
      .catch(errorResp => error(errorResp));
  }

  async downloadDocumentById(idDocument, success, error, placingStickers = false) {
    try {
      const document = await axiosRequestBlockChain.get(`/document/details/?documentId=${idDocument}`, { headers: authHeader() });

      const currentUser = sessionStorage.getItem('userId');
      const params = {
        documentId: document.data._id,
        path: `${document.data.Path !== '' ? document.data.Path + '/' : ''}${document.data.Name}${document.data.Extension}`,
        companyId: document.data.CompanyId,
        owner: currentUser,
        placingStickers,
      };

      axiosRequestBlockChain.post(`/document/download`, params, { headers: authHeader(), responseType: 'arraybuffer' })
        .then((data) => {
          success(data, document.data.Extension);
        }).catch((errorResp) => {
          error(errorResp);
        });
    } catch (e) {
      error();
    }
  }

  existDocument(companyId, path, document) {
    return axiosRequestBlockChain.get(`/document/document-exist?companyId=${companyId}&path=${path}&document=${document}`,
      { headers: authHeader() });
  }

  getDocumentTypes(success, error) {
    axiosRequestBlockChain.get(`/document/document-types`, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  findBookStatus(companyId, success, error) {
    axiosRequestBlockChain.get(`/book/status?companyId=${companyId}`, { headers: authHeader() })
      .then((data) => success(data))
      .catch(errorResp => error(errorResp));
  }

  validateDocumentUpdate(documentId, success, error) {
    axiosRequestBlockChain.get(`/document/validateDocumentUpdate/${documentId}`, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch((errorResp) => error(errorResp))
  }

  restoreDocument(data, success, error) {
    axiosRequestBlockChain.post(`/document/restore`, data, { headers: authHeader() })
      .then((data) => success(data))
      .catch(errorResp => error(errorResp));
  }

  downloadThumbnail(params, success, error) {
    axiosRequestBlockChain.post(`/document/download/thumbnail`, params, {
      headers: authHeader(),
      responseType: 'arraybuffer'
    })
      .then((data) => success(data))
      .catch(errorResp => error(errorResp));
  }
}
