import React, { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { makeStyles, Grid } from "@material-ui/core";
import { getCurrentUser } from "../../core/helpers";
import { AlertComponent } from "../AlertComponent";
import { deleteFromStorageProvider, getSaveRequest } from "../../core/http/functionRequests";
import { getHash } from "../../core/helpers/documentsHelper";
import { PDFDocument } from "pdf-lib";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    backgroundColor: "rgba(33, 70, 183, 0.05)",
    height: "138px",
    textAlign: "center",
    paddingTop: "20px",
    "& p": {
      color: "rgba(122, 146, 197, 0.87)",
    },
    border: "1px dashed rgba(92, 123, 240, 0.6)",
    borderRadius: "6px",
  },
  alertMessage: {
    marginLeft: "27px",
    marginTop: "28px",
    marginRight: "28px",
  },
  p: {
    margin: 0,
  },
}));


function DropzoneFiles(props) {
  const { setDocuments, documents, currentDocuments, docName, setName } = props;
  const classes = useStyles();
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [message, setMessage] = useState("")
  const [aborted, setAborted] = useState(false)

  const getUploadParams = async (properties) => {
    const { file, meta } = properties;
    try {
      const { storageProvider } = getCurrentUser();
      const body = new FormData()

      const extension = /(?:\.([^.]+))?$/.exec(meta.name)[0]
      meta.extension = extension;
      meta.storageProvider = storageProvider;


      if (storageProvider === "kaleido") {
        body.append('file', file)
        const sizeLimit = 52428800 // 50 MB
        if (meta.size >= sizeLimit) {
          setMessage("No se puede subir el documento porque tiene un tamaño mayor a 50 MB")
          setOpenAlertConfirm(true)
          return { url: "" }
        }
        return { url: 'https://httpbin.org/post', body, meta }
      }

      const { request, name } = await getSaveRequest(extension);
      body.append('file', file)
      const readFile = await getHash(file, extension);

      meta.editable = false;
      if (extension === ".pdf") {
        const pdfDoc = await PDFDocument.load(readFile.buffer)
        const form = pdfDoc.getForm()
        const fields = form.getFields()
        meta.editable = fields.length > 0;
      }
      meta.hash = readFile.hash
      meta.blobname = name;

      let originalName = meta.name.split(extension)[0];

      if (Object.values(documents).filter(el => el).length === 0) {
        if (docName === "") {
          setName(originalName)
          meta.originalName = originalName
        } else {
          meta.originalName = docName
        }
      } else {
        meta.originalName = originalName
      }

      const existsInSelection = documents.find(el => el?.name === originalName)
      const existsInPath = currentDocuments.find(el => el?.name === originalName)
      if (existsInSelection) {
        setMessage("Ya estas subiendo un documento con nombre: " + originalName)
        setOpenAlertConfirm(true)
        return { url: "" }
      }

      if (existsInPath) {
        setMessage("Ya existe un documento en la ubicación con nombre: " + originalName)
        setOpenAlertConfirm(true)
        return { url: "" }
      }

      return { ...request, body, meta }
    } catch (error) {
      console.log(error);
      console.error("No se pudo obtener la url");
      return { url: "" }
    }
  };

  const restoreStates = (name) => {
    setDocuments(prev => ({ ...prev, [name]: null }))
  }

  const handleChangeStatus = ({ file, meta }, status) => {

    // console.log(status + ': ' + file.name);

    if (status === "preparing") {
      setAborted(false)
      return
    }

    if (status === "restarted") {
      setAborted(false)
      return
    }

    if (status === "uploading") {
      const objResponse = {
        name: meta.originalName,
        size: file.size,
        extension: meta.extension,
        storedName: meta.blobname,
        editable: meta.editable,
        loading: true
      }
      setDocuments(prev => ({ ...prev, [meta.blobname]: objResponse }))
      return
    }

    if (status === "done") {
      if (meta.storageProvider === "kaleido") {
        // field.input.onChange([file])
      } else {
        const objResponse = {
          name: meta.originalName,
          size: file.size,
          extension: meta.extension,
          storedName: meta.blobname,
          editable: meta.editable,
          loading: false
        }
        if (meta.hash) objResponse.hash = meta.hash;
        setDocuments(prev => ({ ...prev, [meta.blobname]: objResponse }))
      }
      return
    }

    if (status === "removed" && !aborted) {
      if (meta.storageProvider === "blob") {
        const docName = `${meta.blobname}${meta.extension}`
        deleteFromStorageProvider(docName)
        restoreStates(meta.blobname)
      }
      // request para eliminar documento
      return
    }

    if (status === "aborted") {
      setAborted(true)
      if (meta.blobname) restoreStates(meta.blobname)
      // no se necesita borrar el documento en este punto
      return
    }

  };

  return (
    <div>
      <Dropzone
        maxFiles={50}
        multiple
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        inputWithFilesContent={'Agregar archivos'}
        inputContent={() => (
          <Grid
            container
            direction="column"
            alignContent="center"
            justifyContent="center"
            alignItems="center"
          >
            <p className={classes.p}>Da click para buscar en tus archivos o arrastralo de otra ventana</p>
          </Grid>
        )}
        styles={{
          dropzone: {
            overflow: "auto",
            backgroundColor: "rgba(33, 70, 183, 0.1)",
            border: "1px dashed #5C7BF0",
            maxHeight: "250px"
          },
          dropzoneActive: { borderColor: "green" },
          preview: {
            padding: '0px 10px 0px 10px',
            minHeight: '30px',
          }
        }}
      />
      {openAlertConfirm &&
        <AlertComponent
          open={openAlertConfirm}
          title={message}
          onConfirm={() => setOpenAlertConfirm(false)}
          type={"error"}
        />
      }

    </div>
  );
}

export default DropzoneFiles;
