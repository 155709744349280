import { useEffect, useState } from "react"
import { getAvailableSignatures, getMultipleSignaturesLinks } from "../core/http/functionRequests";
import { getPendingSigns } from "../core/http/functionRequests/calendar.http";


export const useAvailableSignatures = () => {

  const [openCalendar, setOpenCalendar] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [loadActivity, setLoadActivity] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAvailableSignatures, setOpenAvailableSignatures] = useState(false);
  const [documentsAvailable, setDocumentsAvailable] = useState({ remaining: "", date: "" })
  const [multipleSign, setMultipleSign] = useState([])

  const signsRequest = async () => {
    try {
      const response = await getMultipleSignaturesLinks()
      setMultipleSign(response)
    } catch (error) {
      console.error(error);
      setOpenCalendar(false);
      setOpenActivity(false);
      setOpenAvailableSignatures(false);
      setOpenAlert(true);
    }
  }

  const handleOpenAvailableSignatures = async () => {
    setOpenAvailableSignatures(true);
    try {
      const info = await getAvailableSignatures();
      setDocumentsAvailable(info);
    } catch (error) {
      console.error(error);
      setOpenCalendar(false);
      setOpenActivity(false);
      setOpenAvailableSignatures(false);
      setOpenAlert(true);
    }
  }

  const handleCloseModal = () => {
    setOpenAvailableSignatures(false);
    setDocumentsAvailable({ remainingNom151: "", remaining: "", date: "" });
  }

  useEffect(() => {
    signsRequest();
    // eslint-disable-next-line
  }, [])

  return {
    multipleSign,
    openCalendar,
    openActivity,
    loadActivity,
    openAvailableSignatures,
    documentsAvailable,
    openAlert,
    setOpenCalendar,
    setOpenActivity,
    setLoadActivity,
    setOpenAlert,
    handleOpenAvailableSignatures,
    handleCloseModal,
  }
}
