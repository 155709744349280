import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';
import { getFileSystem } from "../core/http/functionRequests";
import { useDispatch, useSelector } from "react-redux";
import { fileSystemActions } from "../core/actions/fileSystem.actions";
import { tokenExpired } from "../core/utils";
import { authActions } from "../core/actions";

export const useVault = () => {

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const vault = useSelector(state => state.fileSystemReducer.vault)
  const loading = useSelector(state => state.fileSystemReducer.loading)
  const currentUser = useSelector(state => state.authReducer.currentUser)
  const arrayPath = useSelector(state => state.fileSystemReducer.arrayPath)
  const folders = useSelector(state => state.fileSystemReducer.foldersSearch)
  const openBackDrop = useSelector(state => state.fileSystemReducer.openBackDrop)
  const documents = useSelector(state => state.fileSystemReducer.documentsSearch)
  const itemSelected = useSelector(state => state.fileSystemReducer.itemSelected)
  const currentFolderId = useSelector(state => state.fileSystemReducer.currentFolderId)
  const filterTags = useSelector(state => state.fileSystemReducer.filterTags)
  const sets = useSelector(state => state.fileSystemReducer.sets)

  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const requestFileSystem = async () => {

    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut());
      return;
    }
    dispatch(fileSystemActions.loadingItems(true))
    dispatch(fileSystemActions.selectItem(null))
    const { vaultId } = params;
    const { search } = location;
    const pathFolder = new URLSearchParams(search).get('p');
    try {
      const data = await getFileSystem(vaultId, pathFolder ?? "")
      dispatch(fileSystemActions.saveItems(data))
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(fileSystemActions.loadingItems(false))
    }
  }

  const setItemSelected = (item) => {
    dispatch(fileSystemActions.selectItem(item))
  }

  const setOpenBackDrop = (open) => {
    dispatch(fileSystemActions.openBackDrop(open))
  }

  const goToPath = (path) => {
    history.push({
      search: `p=${encodeURIComponent(path)}`
    })
  }

  const saveArrayPath = (vaultName) => {
    const { search } = location;

    const pathFolder = new URLSearchParams(search).get('p');
    let array = []
    if (pathFolder) {
      array = [vaultName, ...pathFolder.split('/')]
      dispatch(fileSystemActions.saveArrayPath(array))
      return
    }

    if (vault) {
      dispatch(fileSystemActions.saveArrayPath([vaultName]))
      return
    }
  }

  const searchElementLocal = (input) => {
    dispatch(fileSystemActions.searchElements(input))
  }

  const enableDescription = (payload) => {
    dispatch(fileSystemActions.includeDescription(payload))
  }

  const addTag = (tag) => {
    dispatch(fileSystemActions.addFilterTag(tag))
  }

  const removeTag = (tag) => {
    dispatch(fileSystemActions.removeFilterTag(tag))
  }

  return {
    currentUser,
    vault: vault ?? null,
    itemSelected: itemSelected ?? null,
    documents: documents ?? [],
    folders: folders ?? [],
    loading: loading ?? true,
    arrayPath: arrayPath ?? [],
    currentFolderId: currentFolderId ?? null,
    openBackDrop: openBackDrop ?? false,
    filterTags: filterTags ?? [],
    sets: sets ?? [],
    requestFileSystem,
    setItemSelected,
    goToPath,
    saveArrayPath,
    setOpenBackDrop,
    searchElementLocal,
    enableDescription,
    addTag,
    removeTag,
  }
}
